import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  mainTeaserTitle: 'Modernisierung und Individualisierung <br/>einer Touristikplattform für <br/>S-Markt und Mehrwert',
  sectionTitle: 'S-Markt und Mehrwert - Vertriebsstratege der Sparkassen-Finanzgruppe',
  sectionSectionTitle: 'Das Unternehmen',
  sectionText: '<p>S-Markt und Mehrwert, Dienstleister und Partner für Vertriebs- und Marketingstrategien der Auftraggeber aus der Sparkassen-Finanzgruppe. Das Unternehmen strebte nach Sicherheit, Flexibilität, Effizienzsteigerung und Modernisierung, um seine Dienstleistungen attraktiver für die Sparkassen und deren Reiseprodukte zu gestalten und die Kundenzufriedenheit zu steigern.</p>',
  sectionListItem1Title: 'Initialprojekt und Beratung',
  sectionListItem1Text: 'Hier lag der Fokus auf der Entwicklung des Whitelabel-Systems, der Mandanten-Verwaltung und der Anpassungsmöglichkeiten für die Mandanten. Die Beratung und Schulungen des Kunden waren hierbei essenziell.',
  sectionListItem2Title: 'Initialprojekt und Beratung',
  sectionListItem2Text: 'Hier lag der Fokus auf der Entwicklung des Whitelabel-Systems, der Mandanten-Verwaltung und der Anpassungsmöglichkeiten für die Mandanten. Die Beratung und Schulungen des Kunden waren hierbei essenziell.',
  sectionListItem3Title: 'Initialprojekt und Beratung',
  sectionListItem3Text: 'Hier lag der Fokus auf der Entwicklung des Whitelabel-Systems, der Mandanten-Verwaltung und der Anpassungsmöglichkeiten für die Mandanten. Die Beratung und Schulungen des Kunden waren hierbei essenziell.',
  sectionButtonLabel: 'Kostenlos beraten lassen',
  sectionQuoteText1: 'Das ist ein wunderbares Zitat über die Zusammenarbeit mit freshcells. Jeder Kunde sollte in einer honeymoon phase abgefangen werden und ein positves statement abgeben.',
  sectionGalleryTitle: 'Impressionen',
  techSectionTitle: 'Tech & Services',
  techSectionText: '<p>Die Technologien von Strapi, Amadeus, Kubernetes Hosting und den freshcells hauseigenen Produkten wie freshMS®, QuickStart und Fusion wurden eingesetzt, um diese hohen Anforderungen zu erfüllen.</p><p>Darüber hinaus sind sämtliche angebundenen Vertriebsstrecken mit individualisierten Mandantenkennern ausgesteuert.</p>',
})
