// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
// import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Layout from '../../../components/Layout/index'
import Header from '../../../modules/Header'
import Footer from '../../../modules/Footer'
import CaseStudyModern from '../../../components/CaseStudyModern'
import messages from '../../../components/CaseStudyModern/messages/Test/Test.lang'
import SEO from '../../../components/seo'

/* eslint-disable max-len */
const mainTeaserBg = (
  <StaticImage src="../../../images/case-study-modern/test/Mainteaser.png" alt="" objectFit="cover" className="imageCover" />
)
const caseLogo = (
  <StaticImage src="../../../images/case-study-modern/test/caseLogo.png" alt="" objectFit="contain" className="image" />
)
const caseGalleryExample = (
  <StaticImage src="../../../images/case-study-modern/test/Main.png" alt="" objectFit="contain" className="image" />
)
const caseGalleryExampleBig = (
  <StaticImage src="../../../images/case-study-modern/test/test-img-big.png" alt="" objectFit="contain" className="image" />
)
const quoteMan = (
  <StaticImage src="../../../images/case-study-modern/test/QuoteMan.png" alt="" objectFit="cover" className="imageCover" />
)
const sliderImage = (
  <StaticImage src="../../../images/case-study-modern/test/SliderImage.png" alt="" objectFit="contain" className="image" />
)
const contactImage = (
  <StaticImage src="../../../images/case-study-modern/Rebecca.png" alt="" objectFit="cover" className="imageCover" />
)

// Tech
const strapiImage = (
  <StaticImage src="../../../images/case-study-modern/tech/Strapi.png" alt="" objectFit="contain" className="image" />
)
const kubernetesImage = (
  <StaticImage src="../../../images/case-study-modern/tech/kubernetes.png" alt="" objectFit="contain" className="image" />
)
const amadeusImage = (
  <StaticImage src="../../../images/case-study-modern/tech/amadeus.png" alt="" objectFit="contain" className="image imageAmadeus" />
)
const nextjsImage = (
  <StaticImage src="../../../images/case-study-modern/tech/nextjs.png" alt="" objectFit="contain" className="image" />
)
const tsboImage = (
  <StaticImage src="../../../images/case-study-modern/tech/TSBO.png" alt="" objectFit="contain" className="image" />
)
const freshmsImage = (
  <StaticImage src="../../../images/case-study-modern/tech/freshMS.png" alt="" objectFit="contain" className="image" />
)
const fusionImage = (
  <StaticImage src="../../../images/case-study-modern/tech/Fusion.png" alt="" objectFit="contain" className="image" />
)
/* eslint-enable max-len */

// import { projectsList } from '../../../components/CaseStudy/helpers'

type Props = {
  ...StyleProps
}

const data = [
  {
    type: 'MainTeaser',
    title: messages.mainTeaserTitle,
    logo: caseLogo,
    logoBg: 'white',
    modeSettings: {
      mode: 'dark',
      bgImg: mainTeaserBg,
      bgColour: 'white'
    }
  },
  {
    type: 'MainTeaser',
    title: messages.mainTeaserTitle,
    logo: caseLogo,
    logoBg: 'black',
    modeSettings: {
      mode: 'light',
      bgColour: 'secondary'
    }
  },
  {
    type: 'Section',
    modeSettings: {
      mode: 'dark',
      bgColour: 'black'
    },
    sectionTitle: messages.sectionSectionTitle,
    sectionIcon: 'icon-company',
    sectionIconColour: 'accent',
    title: messages.sectionTitle,
    text: messages.sectionText,
    list: [
      {
        title: messages.sectionListItem1Title,
        text: messages.sectionListItem1Text,
      },
      {
        title: messages.sectionListItem2Title,
        text: messages.sectionListItem2Text,
      },
      {
        title: messages.sectionListItem3Title,
        text: messages.sectionListItem3Text,
      },
    ],
    buttonLink: '/contact/',
    buttonLabel: messages.sectionButtonLabel,
    galleryTitle: messages.sectionGalleryTitle,
    galleryAlignment: 'left',
    gallery: [
      {
        img: caseGalleryExample
      },
      {
        img: caseGalleryExampleBig
      },
      {
        img: caseGalleryExample
      },
      {
        img: caseGalleryExampleBig
      },
    ],
    quotes: [
      {
        img: quoteMan,
        quote: messages.sectionQuoteText1,
        name: 'Max Mustermann',
        position: 'CEO',
        company: 'Fantasy Company GmbH',
      },
    ],
    imageRow: {
      title: messages.sectionGalleryTitle,
      items: [
        {
          img: sliderImage
        },
        {
          img: caseGalleryExampleBig
        },
        {
          img: sliderImage
        },
        {
          img: caseGalleryExampleBig
        },
        {
          img: sliderImage
        },
      ]
    }
  },
  {
    type: 'Section',
    modeSettings: {
      mode: 'light',
    },
    sectionTitle: messages.sectionSectionTitle,
    sectionIcon: 'icon-company',
    sectionIconColour: 'accent',
    title: messages.sectionTitle,
    text: messages.sectionText,
    list: [
      {
        title: messages.sectionListItem1Title,
        text: messages.sectionListItem1Text,
      }
    ],
    buttonLink: '/contact/',
    buttonLabel: messages.sectionButtonLabel,
    galleryTitle: messages.sectionGalleryTitle,
    galleryAlignment: 'right',
    gallery: [
      {
        img: caseGalleryExample
      }
    ]
  },
  {
    type: 'TechSection',
    modeSettings: {
      mode: 'dark',
      bgColour: 'black',
      texture: 'light'
    },
    title: messages.techSectionTitle,
    text: messages.techSectionText,
    techLogos: [
      {
        img: strapiImage
      },
      {
        img: kubernetesImage
      },
      {
        img: amadeusImage
      },
      {
        img: nextjsImage
      }
    ],
    techLogosMore: true,
    productLogos: [
      {
        img: tsboImage,
        url: '/products/travelsandbox/'
      },
      {
        img: freshmsImage,
        url: '/products/freshms/'
      },
      {
        img: fusionImage,
        url: '/products/fusion/'
      }
    ],
  },
  {
    type: 'Section',
    modeSettings: {
      mode: 'light',
      texture: 'dark'
    },
    quotes: [
      {
        img: quoteMan,
        quote: messages.sectionQuoteText1,
        name: 'Max Mustermann',
        position: 'CEO',
        company: 'Fantasy Company GmbH',
      },
    ],
  },
  {
    type: 'Section',
    modeSettings: {
      mode: 'dark',
      bgColour: 'black'
    },
    imageRow: {
      title: messages.sectionGalleryTitle,
      items: [
        {
          img: sliderImage
        },
        {
          img: sliderImage
        },
        {
          img: sliderImage
        },
        {
          img: sliderImage
        },
        {
          img: sliderImage
        },
      ]
    }
  },
  {
    type: 'RelatedCS',
    relatedCases: [
      'dertouristik',
      'lhh',
      'aldiana',
      'bedfinder',
      'etravel',
      'plattenplaner',
      'weekend',
      'ytravel',
    ]
  },
  {
    type: 'FloatingCTA',
    img: contactImage,
  },
]

const CaseModernTest = ({ styles }: Props) => {
  // const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer modern />} header={<Header withTeaser />} styles={styles.root}>
      <SEO
        title="Test title"
        description="Test description"
        // lang={locale}
        nofollow
      />
      <CaseStudyModern data={data} />
    </Layout>
  )
}

export default applyStylingHoc({})<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CaseModernTest)
